


















































import {
  Component,
  Prop,
  Vue
} from 'vue-property-decorator';
import InventoryItemList, {
  IInventoryItemList_Item
} from '../modules/InventoryItem/List';

import RentalObjectGroupList, {
  IRentalObjectGroupList_Item
} from '../modules/RentalObjectGroup/List';

@Component
export default class InventoryItemSelect extends Vue {
  @Prop() readonly value ? : IInventoryItemList_Item | null
  @Prop(Boolean) readonly disabled ? : boolean
  @Prop(String) readonly invalidFeedback ? : string

  isBusy = false;

  selectedGroup = null as number | null;
  searchQuery = '';

  perPage = 10;
  currentPage = 1;

  inventoryItems = new InventoryItemList();
  groups = new RentalObjectGroupList();

  fields = [
    { key: 'Number',       label: this.$t('Inventory item')                             },
    { key: 'ObjectNumber', label: ''                                                    },
    { key: 'Name',         label: this.$t('Rental object')                              },
    { key: 'Capacity',     label: this.$t('Capacity'),      class: 'display-priority-3' },
    { key: 'Group',        label: this.$t('Group'),         class: 'display-priority-5' },
    { key: 'RentalType',   label: this.$t('Rental period'), class: 'display-priority-2' }, 
    { key: 'Free',         label: this.$t('Free')                                       },
  ];

  async fetchInventoryItems() {
    this.isBusy = true;
    this.inventoryItems = await InventoryItemList.Fetch(this.currentPage, this.perPage, this.searchQuery, undefined, this.selectedGroup);
    this.isBusy = false;
  }

  async fetchRentalObjectGroups() {
    this.isBusy = true;
    this.groups = await RentalObjectGroupList.FetchAll();
    this.groups.Items = ([{
      Id: null,
      Name: this.$t('All groups')
    }] as IRentalObjectGroupList_Item[]).concat(this.groups.Items);
    this.isBusy = false;
  }

  resetPageAndReloadInventoryItems() {
    this.pageSelected(1);
  }

  pageSelected(page: number) {
    this.currentPage = page;
    this.fetchInventoryItems();
  }

  inventoryItemSelected(record: IInventoryItemList_Item, idx: number) {
    (this as any).$bvModal.hide('select-inventory-item-modal');
    this.$emit('input', record);
  }

  async mounted() {
    await this.$nextTick();
    await this.fetchRentalObjectGroups();
  }
}
